import * as React from 'react'
import { useParams } from 'react-router-dom'
import { getAllPresentationSlides } from '../facilitatorDelivery/actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '@app/store/configureStore'
import { IPresentationSlide, renderSlideDynamicHTML } from '../facilitatorDelivery/hooks'
import { usePresentationControls } from './hooks'
import { getLanguageValue } from '@app/commonUtils/languageFunctionsHelper'

const PRESENTATION_CURSOR_SELECTOR = '.presentation-cursor, .pc'

type Props = {
  slides: ReadonlyArray<IPresentationSlide>
}

export const SelfDeliveryScroller: React.FC<Props> = (props) => {
  const currentSlideRef = React.useRef<HTMLDivElement>(null)
  const controls = usePresentationControls(props.slides)
  const position = controls.position
  const timeoutRef = React.useRef<number | undefined>(undefined)
  const lang = useSelector((state: RootState) => state.mainReducer.languageText)

  React.useEffect(() => {
    // it takes a while for the browser to render the DOM. we can't
    // know where to scroll before the browser has successfully redrawn
    // the view.
    window.clearTimeout(timeoutRef.current)

    timeoutRef.current = window.setTimeout(() => {
      const slideElem = currentSlideRef.current
      if (!slideElem) {
        return
      }

      const cursors = slideElem.querySelectorAll(PRESENTATION_CURSOR_SELECTOR)

      // look for the last element tagged with the cursor class, or alternatively
      // just scroll to the root of the slide.
      const elem = cursors.length !== 0 ? cursors[cursors.length - 1] : slideElem

      elem.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      })
    }, 100)
  }, [props.slides, position])

  function handleContinueClick(event: React.MouseEvent) {
    event.preventDefault()
    event.stopPropagation()

    controls.next()
  }

  function handleBackClick(event: React.MouseEvent) {
    event.preventDefault()
    event.stopPropagation()

    controls.previous()
  }

  const numStepsForSlide = props.slides[position.slideIndex]?.slideSteps.length ?? 0
  const isAtLastSlideAndStep =
    position.slideIndex === props.slides.length - 1 &&
    ((numStepsForSlide === 0 && position.slideStepIndex === null) ||
      position.slideStepIndex === numStepsForSlide - 1)

  return (
    <div className='d-flex flex-column w-100 h-100'>
      <div className='flex-grow-1 overflow-y-auto'>
        {props.slides.map((slide, index) => {
          if (index > position.slideIndex) {
            return
          }
          const wantSlideStep =
            index === position.slideIndex ? position.slideStepIndex : slide.slideSteps.length
          const html = renderSlideDynamicHTML(slide, wantSlideStep)
          const slideRef = index === position.slideIndex ? currentSlideRef : undefined

          return (
            <div key={index} ref={slideRef}>
              {html}
            </div>
          )
        })}
      </div>
      <div className='bg-white py-2 border-top'>
        <div className='container text-center'>
          <div className='d-flex justify-content-center'>
            <button className='btn btn-lg btn-light me-2 flex-grow-0' onClick={handleBackClick}>
              {getLanguageValue(lang, 'Back')}
            </button>
            <button
              className='btn btn-lg btn-primary flex-grow-1 flex-md-grow-0'
              onClick={handleContinueClick}
              disabled={isAtLastSlideAndStep}
            >
              {getLanguageValue(lang, 'Continue')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export const PreviewPresentationTemplateSelfDelivery: React.FC<unknown> = (props) => {
  const dispatch = useDispatch()
  const params = useParams<'id'>()
  const templateId = params.id ? Number(params.id) : undefined
  const slides = useSelector(
    (state: RootState) => state.facilitatorDeliveryReducer.presentationSlides
  )
  React.useEffect(() => {
    if (!templateId) {
      return
    }
    getAllPresentationSlides(templateId, dispatch)
  }, [templateId])

  return <SelfDeliveryScroller slides={slides} />
}
