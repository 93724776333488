import { ModelType } from '@app/containers/commonInterfaces'

export default {
  getUserDetails: 'api/services/app/commonLookup/getUserDetails',
  getRoles: 'api/services/app/Role/GetRoles',
  getAllPermissions: 'api/services/app/Permission/getAllPermissions',
  getParticipantRespondentsByParticipantId:
    'api/services/app/profileRespondent/getParticipantRespondentsByParticipantId',

  // Tokenauth api's
  logIn: 'api/TokenAuth/Authenticate',
  impersonatedAuthenticate: 'api/TokenAuth/impersonatedAuthenticate',
  externalAuthenticate: 'api/TokenAuth/externalAuthenticate',
  verifyUserEmailConfirmCode: 'api/TokenAuth/verifyUserEmailConfirmCode',
  respondentAuthenticate: 'api/TokenAuth/respondentAuthenticate',
  switchUserRole: 'api/TokenAuth/SwitchUserRole',
  logout: 'api/TokenAuth/Logout',

  // Facilitator dashboard api's
  getFacilitatorDashboardDetails:
    'api/services/app/FacilitatorDashboard/getFacilitatorDashboardDetails',
  getFacilitatorParticipantsProfilesReport:
    'api/services/app/FacilitatorDashboard/getFacilitatorParticipantsProfilesReport',

  // Delivery api's
  updateParticipantsSlide: 'api/services/app/Delivery/updateParticipantsSlide',

  // User api's
  getUsers: 'api/services/app/User/getUsers',
  getUserById: 'api/services/app/User/getUserById',
  getAdminUsers: 'api/services/app/User/getAdminUsers',
  getUserByEmail: 'api/services/app/User/getUserByEmail',
  createOrUpdateUser: 'api/services/app/User/createOrUpdateUser',
  deleteUser: 'api/services/app/User/deleteUser',
  getUsersToConnectAccount: 'api/services/app/User/getUsersToConnectAccount',
  connectFacilitatorToAccount: 'api/services/app/User/connectFacilitatorToAccount',
  getClientFacilitators: 'api/services/app/User/getClientFacilitators',
  getUsersByClientId: (clientId: number) => `api/services/app/User/getUsersByClientId/${clientId}`,

  registerExternalLogin: 'api/TokenAuth/registerExternalLogin',
  getUserExternalLogins: 'api/services/app/UserLogin/getUserExternalLogins',
  deleteUserExternalLogin: 'api/services/app/UserLogin/deleteUserExternalLogin',

  // Presentation template api's
  createPresentationTemplate: 'api/services/app/PresentationTemplate/create',
  updatePresentationTemplate: 'api/services/app/PresentationTemplate/update',
  deletePresentationTemplate: 'api/services/app/PresentationTemplate/delete',
  getPresentationTemplateById: 'api/services/app/PresentationTemplate/getById',
  getAllPresentationTemplates: 'api/services/app/PresentationTemplate/getAllPresentationTemplates',
  getAllPresentationSlides: 'api/services/app/PresentationTemplateSlide/getByTemplateId', // Getting all slides at once
  getPresentationSlides: 'api/services/app/PresentationTemplateSlide/getAllByTemplateId', // With filtering, sorting, pagination
  createPresentationTemplateSlide: 'api/services/app/PresentationTemplateSlide/create',
  updatePresentationTemplateSlide: 'api/services/app/PresentationTemplateSlide/update',
  deletePresentationTemplateSlide: 'api/services/app/PresentationTemplateSlide/delete',
  getPresentationTemplateSlideById: 'api/services/app/PresentationTemplateSlide/getById',
  getIDIV2CurrentPresentationSlideId:
    'api/services/app/PresentationTemplateSlide/getIDIV2CurrentPresentationSlideId',
  idiV2GetParticipantPresentationSlides:
    'api/services/app/PresentationTemplateSlide/idiV2GetByTemplateId',
  exportPresentationTemplateSlides: (id: number) =>
    `api/services/app/PresentationTemplate/${id}/ExportSlides`,
  importPresentationTemplateSlides: (id: number) =>
    `api/services/app/PresentationTemplate/${id}/ImportSlides`,

  // CoursePresentation api's
  coursePresentationGetById: 'api/services/app/CoursePresentation/getById',
  participantsProfileDeliver: 'api/services/app/CoursePresentation/participantsProfileDeliver',
  getTemplateSlideById: 'api/services/app/CoursePresentation/getTemplateSlideById',
  createPresentation: 'api/services/app/CoursePresentation/createPresentation',
  updateCoursePresentationStatus:
    'api/services/app/CoursePresentation/updateCoursePresentationStatus',
  getAllPresentationParticipants:
    'api/services/app/CoursePresentation/getAllPresentationParticipants',
  getAllPlannedAndOngoingPresentationsByCourseId:
    'api/services/app/CoursePresentation/getAllPlannedAndOngoingPresentationsByCourseId',
  getCompletedParticipantsByProfileIdToSendPresentation:
    'api/services/app/CoursePresentation/getCompletedParticipantsByProfileIdToSendPresentation',
  getParticipantNamesByProfileAndPresentationId:
    'api/services/app/CoursePresentation/getParticipantNamesByProfileAndPresentationId',
  updatePresentation: 'api/services/app/CoursePresentation/updatePresentation',
  sendParticipantPresentationEmail:
    'api/services/app/CoursePresentation/sendParticipantPresentationEmail',
  getUpcomingPresentations: 'api/services/app/CoursePresentation/Upcoming',
  facilitatorPresentation: 'api/services/app/CoursePresentation/facilitatorPresentation',
  profileParticipantPresentation:
    'api/services/app/CoursePresentation/profileParticipantPresentation',
  deletePresentation: (id: number) => `api/services/app/CoursePresentation/${id}`,

  // Profile api's
  getCurrentUserProfile: 'api/services/app/Profile/GetCurrentUserProfileForEdit',
  editUserData: 'api/services/app/Profile/updateCurrentUserProfile',
  updateUserLanguage: 'api/services/app/Profile/updateUserLanguage',
  changePassword: 'api/services/app/Profile/changePassword',
  resetParticipantPassword: 'api/services/app/Profile/resetParticipantPassword',
  getAllProfiles: 'api/services/app/Profile/getAll',
  getAllProfilesWithParticipants: 'api/services/app/Profile/getAllWithParticipants',
  deleteProfile: 'api/services/app/Profile/delete',
  createProfileCulture: 'api/services/app/Profile/createProfileCulture',
  updateProfileType: 'api/services/app/Profile/updateProfileType',
  updateProfileCulture: 'api/services/app/Profile/updateProfileCulture',
  updateProfileRole: 'api/services/app/Profile/updateProfileRole',
  updateProfileRespondents: 'api/services/app/Profile/updateProfileRespondents',
  getProfileById: 'api/services/app/Profile/getProfileById',
  saveProfileEditFieldValue: 'api/services/app/Profile/saveProfileEditFieldValue',
  cancelProfile: 'api/services/app/Profile/cancelProfile',
  editProfileUpdateRole: 'api/services/app/Profile/editProfileUpdateRole',
  getProfileParticipantsByStatus: 'api/services/app/Profile/getProfileParticipantsByStatus',
  getEditProfileParticipantByIdAndStatus:
    'api/services/app/Profile/getEditProfileParticipantByIdAndStatus',
  getParticipantProfileReport: 'api/services/app/Profile/getParticipantProfileReport',
  getGroupReport: 'api/services/app/Profile/getGroupReport',
  getActiveProfilesByUserId: 'api/services/app/Profile/getActiveProfilesByUserId',
  recalculateProfiles: '/api/services/app/Profile/recalculateProfiles',
  updateProfileClient: 'api/services/app/Profile/updateProfileClient',
  updateCompletionDate: 'api/services/app/Profile/updateCompletionDate',

  // Invoice api's
  getInvoices: 'api/services/app/IDIInvoice/getAll',

  // Profile invoice api's
  getProfileInvoiceAddresses: 'api/services/app/Profile/getProfileInvoiceAddresses',
  getFortnoxCustomers: 'api/services/app/Profile/getFortnoxCustomers',
  getFortnoxInvoicesByCustomerNumber: 'api/services/app/Profile/getFortnoxInvoicesByCustomerNumber',

  getInvoiceArticlesByProfileOrClientSubscriptionId:
    'api/services/app/IDIInvoice/getInvoiceArticlesByProfileOrClientSubscriptionId',
  getProfileInvoiceDetails: 'api/services/app/IDIInvoice/getProfileInvoiceDetails',
  createOrUpdateProfileInvoice: 'api/services/app/IDIInvoice/createOrUpdateProfileInvoice',
  updateFortnoxInvoiceStatus: 'api/services/app/IDIInvoice/updateFortnoxInvoiceStatus',
  sendProfileInvoiceToFortnox: 'api/services/app/IDIInvoice/sendProfileInvoiceToFortnox',

  // Subscription invoice api's
  getClientSubscriptionInvoiceDetails:
    'api/services/app/IDIInvoice/getClientSubscriptionInvoiceDetails',
  createOrUpdateSubscriptionInvoice:
    'api/services/app/IDIInvoice/createOrUpdateSubscriptionInvoice',
  sendSubscriptionInvoiceToFortnox: 'api/services/app/IDIInvoice/sendSubscriptionInvoiceToFortnox',

  // Language api's
  getLanguageTextByName: (langName) =>
    `api/services/app/Language/GetLanguageTextsByLanguageName?languageName=${langName}`,
  getLanguages: 'api/services/app/Language/getLanguages',
  getAllLanguages: 'api/services/app/Language/getAllLanguages',
  createOrUpdateLanguage: 'api/services/app/Language/createOrUpdateIDILanguage',
  getAllLanguageCultures: 'api/services/app/Language/getAllLanguageCultures',
  getFacilitatorCultures: 'api/services/app/Language/getFacilitatorCultures',
  createOrUpdateLanguageText: 'api/services/app/Language/createOrUpdateLanguageText',
  getFacilitatorLanguages: 'api/services/app/Language/getFacilitatorLanguages',
  getParticipantLanguages: 'api/services/app/Language/getParticipantLanguages',
  deleteLanguage: 'api/services/app/Language/deleteLanguage',
  deleteLanguageText: 'api/services/app/Language/deleteLanguageText',
  createDefaultText: 'api/languagetext/create-default',
  getAllLanguageTexts: 'api/services/app/Language/getAllLanguageTexts',
  // 'language text' means translation in plain english.
  exportLanguageTexts: 'api/services/app/Language/Export',
  importLanguageTexts: 'api/services/app/Language/Import',

  // IDI Client api's
  getClients: 'api/services/app/idiClient/getAll',
  createClient: 'api/services/app/idiClient/create',
  updateClient: 'api/services/app/idiClient/update',
  deleteClient: 'api/services/app/idiClient/delete',
  getAllClientsByUser: 'api/services/app/idiClient/getAllClientsByUser',
  updateFacilitatorDefaultClient: 'api/services/app/idiCLient/updateFacilitatorDefaultClient',
  deleteFacilitatorClient: 'api/services/app/idiClient/deleteFacilitatorClient',
  createClientByFacilitator: 'api/services/app/idiClient/createClientByFacilitator',
  disconnectUserFromClient: 'api/services/app/idiClient/disconncetUserFromClient',
  isFacilitatorInClientRole: 'api/services/app/idiClient/isFacilitatorInClientRole',

  // ClientSubscription api's
  getAllClientSubscriptions: 'api/services/app/clientSubscription/getAllByClientId',
  getClientSubscriptionById: 'api/services/app/clientSubscription/getById',
  createClientBilling: 'api/services/app/clientSubscription/create',
  updateClientBilling: 'api/services/app/clientSubscription/update',

  // Subscription & Billing api's
  getAnnualOrMonthlySubscriptions: 'api/services/app/subscription/getAnnualOrMonthlySubscriptions',

  // Messages api's
  getAllSavedMessages: 'api/services/app/savedMessage/getAll',
  createSavedMessage: 'api/services/app/savedMessage/create',
  updateSavedMessage: 'api/services/app/savedMessage/update',
  deleteSavedMessage: 'api/services/app/savedMessage/delete',

  // Account api's
  verifyUserExists: 'api/services/app/User/verifyUserExists',
  resetPassword: 'api/services/app/Account/resetPassword',
  verifyEmail: 'api/services/app/Account/ActivateEmail',
  sendResetPasswordCode: 'api/services/app/Account/SendPasswordResetCode',

  // Placements api's
  getAllPlacements: 'api/services/app/Placement/getAll',
  updatePlacements: 'api/services/app/Placement/update',
  createPlacements: 'api/services/app/Placement/create',

  // Words api's
  getAllWordsByCultureId: 'api/services/app/AllWords/getAllWordsByCultureId',
  updateWord: 'api/services/app/AllWords/update',
  createWord: 'api/services/app/AllWords/create',

  // Courses api's
  getAllCourses: 'api/services/app/AcademyCourses/getAll',
  createCourse: 'api/services/app/AcademyCourses/create',
  getCoursebyId: 'api/services/app/AcademyCourses/getById',
  updateCourse: 'api/services/app/AcademyCourses/update',
  deleteCourse: 'api/services/app/AcademyCourses/delete',
  toggleActiveStatus: 'api/services/app/AcademyCourses/toggleEnabled',
  getOrderedCourses: 'api/services/app/AcademyCourses/getOrderedCourses',
  updateCourseOrder: 'api/services/app/AcademyCourses/updateCourseOrder',
  getCategoryCourseLessons: 'api/services/app/AcademyCourses/getCategoryCourseLessons',
  getAcademyCoursesLanguages: 'api/services/app/AcademyCourses/languages',
  getParticipantUserNames: '/api/services/app/user/getParticipantUserNames',

  // Course Permission api's
  createAcademyCoursePermission: 'api/services/app/academyCoursePermission/create',
  getClientNames: 'api/services/app/idiClient/getClientNames',
  updateAcademyCoursePermission: 'api/services/app/academyCoursePermission/update',
  deleteAcademyCoursePermission: 'api/services/app/academyCoursePermission/delete',
  getCoursePermissionByCourseId: 'api/services/app/academyCoursePermission/getByCourseId',

  createCourseParts: 'api/services/app/AcademyCourseParts/create',
  updateCourseParts: 'api/services/app/AcademyCourseParts/update',
  deleteCoursePart: 'api/services/app/AcademyCourseParts/delete',
  getCoursePartsByCourseId: 'api/services/app/AcademyCourseParts/getCoursePartsByCourseId',
  updateCoursePartsOrder: '/api/services/app/AcademyCourseParts/updateCoursePartsOrder',

  // Quiz api's
  createQuiz: 'api/services/app/AcademyQuiz/createQuizAndOptions',
  getQuizById: 'api/services/app/AcademyQuiz/getCourseQuizes',
  updateQuiz: 'api/services/app/AcademyQuiz/update',
  deleteQuiz: 'api/services/app/AcademyQuiz/delete',
  updateQuizOrder: 'api/services/app/AcademyQuiz/updateQuizOrder',

  // AcademyCategory
  getAcademyCategory: 'api/services/app/academyCategory/getAll',
  getAcademyCategories: 'api/services/app/academyCategory/getCategories',
  getCategoryCourses: 'api/services/app/academyCategory/getCategoryCourses',

  // AcademyCourseTag
  getAllTagNames: 'api/services/app/AcademyCourseTag/getAllTagNames',

  // Country & Culture api's
  getCountries: 'api/services/app/Country/getAll',
  countryLocate: 'api/services/app/Country/Locate',

  // Employees api's
  getAllEmployees: 'api/services/app/Employee/getAllEmployees',
  getAllEmployeesByClientId: 'api/services/app/Employee/getAllEmployeesByClientId',
  getEmployeeDetailsById: 'api/services/app/Employee/getEmployeeDetailsById',
  createEmployees: 'api/services/app/Employee/createEmployees',
  updateEmployee: 'api/services/app/Employee/updateEmployee',
  isEmployeeExists: 'api/services/app/Employee/getIsEmployeeExists',
  employeesImportedData: 'api/services/app/Employee/employeesImportedData',
  getProfileEmployeesByClientId: 'api/services/app/Employee/getProfileEmployeesByClientId',
  deleteEmployeeById: 'api/services/app/Employee/deleteEmployeeById',

  // Register new Participant
  registerParticipant: 'api/services/app/User/registerParticipantUser',

  // Participants/Respondents api's
  sendParticipantVerificationCodeEmail:
    'api/services/app/ProfileParticipant/sendParticipantVerificationCodeEmail',
  verifyParticipantVerificationLink: 'api/TokenAuth/verifyParticipantVerificationLink',
  verifyParticipantVerificationCode: 'api/TokenAuth/verifyParticipantVerificationCode',
  getParticipantFormInfo: 'api/services/app/ProfileParticipant/getParticipantFormInfo',
  checkValidParticipantOrRespondentLink:
    'api/services/app/ProfileParticipant/CheckValidParticipantOrRespondentLink',
  sendParticipantPasswordEmail: 'api/services/app/ProfileParticipant/sendParticipantPasswordEmail',
  getProfileParticipantDemographicById:
    'api/services/app/ProfileParticipant/getProfileParticipantDemographicById',
  updateParticipantDemographics:
    'api/services/app/ProfileParticipant/updateParticipantDemographics',
  getProfileParticipantRole: 'api/services/app/ProfileParticipant/getProfileParticipantRole',
  updateParticipantFormLanguage: 'api/services/app/ProfileParticipant/updateFormLanguage',
  updateParticipantLanguage: 'api/services/app/profileParticipant/updateParticipantLanguage',
  canParticipantChangeLanguageWithoutEffect:
    'api/services/app/profileParticipant/canParticpantChangeLanguageWithoutEffect',
  getUserForRespondents: 'api/services/app/ProfileParticipant/getUserForRespondents',
  getRespondentEmailPreview: 'api/services/app/ProfileRespondent/getRespondentEmailPreview',
  fillParticipantProfileSelfForm:
    'api/services/app/ProfileParticipant/fillParticipantProfileSelfForm',
  updateParticipantProfileLock: 'api/services/app/ProfileParticipant/updateParticipantProfileLock',
  createProfileParticipants: 'api/services/app/profileParticipant/createParticipants',
  createParticipantsFromEditProfile:
    'api/services/app/profileParticipant/createParticipantsFromEditProfile',
  deleteParticipant: 'api/services/app/profileParticipant/delete',
  createEmployeeParticipantsWithRole:
    'api/services/app/profileParticipant/createEmployeeParticipantsWithRole',
  updateProfileParticipantName: 'api/services/app/profileParticipant/updateProfileParticipantName',
  sendParticipantsInvitation: 'api/services/app/profileParticipant/sendParticipantsInvitation',
  previewParticipantInvitation: 'api/services/app/profileParticipant/previewParticipantInvitation',
  publishProfile: 'api/services/app/profileParticipant/publishProfile',
  unPublishProfile: 'api/services/app/profileParticipant/unPublishProfile',
  deliverProfilesManually: 'api/services/app/profileParticipant/deliverProfilesManually',
  unDeliverProfilesManually: 'api/services/app/profileParticipant/unDeliverProfilesManually',
  sendPublishOrManualProfileDeliverEmail:
    'api/services/app/profileParticipant/sendPublishOrManualProfileDeliverEmail',
  saveParticipantFieldValue: 'api/services/app/profileParticipant/saveParticipantFieldValue',
  getParticipantDashboardInfo: 'api/services/app/profileParticipant/getParticipantDashboardInfo',
  getParticipantDashboardInfoForFacilitator:
    'api/services/app/profileParticipant/getParticipantDashboardInfoForFacilitator',
  getMyIDIProfiles: 'api/services/app/profileParticipant/getMyIDIProfiles',
  idiV2SendOnlineStatusToFacilitator:
    'api/services/app/profileParticipant/idiV2SendOnlineStatusToFacilitator',
  disconnectParticipantFromPresentation:
    'api/services/app/profileParticipant/disconnectParticipantFromPresentation',
  updateProfileParticipantPdfDownload:
    'api/services/app/profileParticipant/updateProfileParticipantPdfDownload',
  getParticipantPresentationByPresentationId:
    'api/services/app/profileParticipant/getParticipantPresentationByPresentationId',
  createProfileByParticipant: 'api/services/app/profileParticipant/createProfileByParticipant',
  updateRespondentFormLanguage: 'api/services/app/ProfileRespondent/updateFormLanguage',
  fillProfileRespondentOtherForm:
    'api/services/app/ProfileRespondent/fillProfileRespondentOtherForm',
  createManualProfileParticipantRespondents:
    'api/services/app/ProfileRespondent/createManualProfileParticipantRespondents',
  createColleaguesAsProfileParticipantRespondents:
    'api/services/app/ProfileRespondent/createColleaguesAsProfileParticipantRespondents',
  sendRespondentsInvitation: 'api/services/app/ProfileRespondent/sendRespondentsInvitation',
  deleteRespondent: 'api/services/app/ProfileRespondent/delete',
  transferProfile: 'api/services/app/profileParticipant/transfer',

  // Form filled api's
  getParticipantOrRespondentWords:
    'api/services/app/formFilledData/getParticipantOrRespondentWords',
  updateRespondentFormFilledDataAnswer:
    'api/services/app/formFilledData/updateRespondentFormFilledDataAnswer',
  updateParticipantFormFilledDataAnswer:
    'api/services/app/formFilledData/updateParticipantFormFilledDataAnswer',

  //Academy endpoints
  getCoursesByUser: 'api/services/app/academyCourses/getCoursesByUser',
  getUserCourseParts: 'api/services/app/academyUserCoursePartDetail/getCourseParts',
  createOrUpdateUserCoursePartDetail:
    'api/services/app/academyUserCoursePartDetail/createOrUpdateUserCoursePartDetail',
  createOrUpdateUserQuizDetail:
    'api/services/app/AcademyUserQuizDetail/CreateOrUpdateUserQuizDetail',
  getUserQuizDetail: 'api/services/app/AcademyUserQuizDetail/getUserQuizDetail',
  getCourseQuizes: 'api/services/app/academyQuiz/getCourseQuizes',
  getCourseDetails: 'api/services/app/academyQuiz/getCoursePartsAndQuizeCompletedProgress',
  restartCourse: 'api/services/app/academyCourses/restartCourse',
  updateTimeSpent: 'api/services/app/academyUserCoursePartDetail/updateTimeSpent',
  courseStarted: 'api/services/app/academyUserCourse/createOrUpdate',
  markAsFinish: 'api/services/app/academyUserCourse/markAsFinish',

  //Resources endpoints
  getNewsList: 'api/services/app/news/getAll',
  createNewsPost: 'api/services/app/news/create',
  updateNewsPost: 'api/services/app/news/update',
  deleteNewsPost: 'api/services/app/news/delete',

  //document endpoints
  getDocumentsList: '/api/services/app/document/getAll',
  createDocument: '/api/services/app/document/create',
  updateDocument: '/api/services/app/document/update',
  deleteDocument: '/api/services/app/document/delete',

  //document Category
  getAllDocumentCategory: '/api/services/app/documentcategory/getAll',
  //information endpoints
  getLatestNews: 'api/services/app/news/getAllPublishedNews',
  getNewsById: 'api/services/app/news/getById',
  //Subscription endpoints
  createSubscription: 'api/services/app/subscription/create',
  deleteSubscription: 'api/services/app/subscription/delete',
  updateSubscription: 'api/services/app/subscription/update',
  getAllSubscriptions: 'api/services/app/subscription/getAll',
  getSubscriptionById: 'api/services/app/subscription/getById',

  //Currency endpoints
  getAllCurrencies: 'api/services/app/currency/getAll',
  createCurrency: 'api/services/app/currency/create',
  deleteCurrency: 'api/services/app/currency/delete',
  updateCurrency: 'api/services/app/currency/update',
  getCurrencyById: 'api/services/app/currency/getById',

  //media endpoints
  getMediaImageFile: '/mediafile/get',
  getParticipantMedia: '/mediafile/getParticipantMedia',

  //profile description Category
  getProfileDescription: '/api/services/app/ProfileDescription/GetAll',
  createProfileDescription: '/api/services/app/ProfileDescription/Create',
  updateProfileDescription: '/api/services/app/ProfileDescription/Update',
  deleteProfileDescription: '/api/services/app/ProfileDescription/delete',
  getProfileDescriptionById: '/api/services/app/ProfileDescription/GetById',

  //audit log
  getAuditLogs: 'api/services/app/AuditLog/GetAuditLogs',

  // email templates
  getEmailTemplates: 'api/email-template',
  updateEmailTemplate: (id: number) => `api/email-template/${id}`,
  createEmailTemplate: 'api/email-template',
  deleteEmailTemplate: (id: number) => `api/email-template/${id}`,

  // logs
  getLogs: 'api/log',
  // rating
  submitRating: 'api/rating',

  // profile pdf
  getParticipantProfilePdfFile: 'api/services/app/profilepdf/individual',
  getParticipantProfileReportHtml: 'api/services/app/profilepdf/view',

  // alerts
  getAlerts: 'api/alert',
  createAlert: 'api/alert',
  updateAlert: (id: number) => `api/alert/${id}`,
  deleteAlert: (id: number) => `api/alert/${id}`,
  interactAlert: (id: number) => `api/alert/${id}/interact`,
  deleteUserInteractions: (id: number) => `api/alert/${id}/interact`,

  // model permissions
  getModelPermissions: (type: ModelType, modelId: number) =>
    `/api/model-permission?modelType=${type}&modelId=${modelId}`,
  updateModelPermissions: `/api/model-permission`,

  // Guide
  getOrPostGuide: 'api/guide', // get & post

  getConsents: 'api/consent',
  createConsent: 'api/consent',
  updateConsent: (id: number) => `api/consent/${id}`,
  deleteConsent: (id: number) => `api/consent/${id}`,
  answerConsent: (id: number) => `api/consent/${id}/answer`,
}
