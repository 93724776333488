import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AnyAction } from 'redux'

import { IFocusError } from '../../../../components/formComponents/input'
import {
  ITranslationObject,
  getLanguageValue,
} from '../../../../commonUtils/languageFunctionsHelper'
import { ValidationHelper } from '../../../validationHelper'
import { getAllCountries } from '../../../clientList/addEditModal/action'
import { IDropdownList, IDropdownSelectedItem } from '@app/components/formComponents/dropdownSelect'
import { addToast, setSpinner } from '../../../actions'
import { registerNewUser } from './actions'
import { getFacilitatorCultures } from '../../../languageList/actions'
import {
  IRegisterParticipantAccountInfo,
  IRegisterParticipantFocusInput,
  IRegisterParticipantPayload,
  RegisterParticipantInputs,
  RegisterParticipantTypeOfUser,
  getTypeOfUsers,
} from './interface'
import { SocialProviderTypes } from '../../../commonEnums'
import { ICountry, ICulture } from '@app/containers/commonInterfaces'
import { pushToDataLayer } from '@app/commonUtils/google'
import { getCountryCode, ICountryLocateResponse } from '@app/commonUtils/countryHelper'
import { pushToMatomo } from '@app/commonUtils/matomo'

export const useRegisterPage = (languageText: ITranslationObject) => {
  const dispatch = useDispatch()

  const initialFocusInputState: IFocusError = {
    touched: false,
    errorMessage: '',
  }
  const [disableConfirmPassword, setDisableConfirmPassword] = useState<boolean>(true)
  const [provider, setProvider] = useState<string>('')
  const [profile, setProfile] = useState<any>()
  const [countries, setCountries] = useState<IDropdownList[]>([])
  const [cultures, setCultures] = useState<IDropdownList[]>([])
  const [accountInfo, setAccountInfo] = useState<IRegisterParticipantAccountInfo>({
    name: '',
    surname: '',
    emailAddress: '',
    password: '',
    confirmPassword: '',
    country: '',
    company: '',
    plan: '',
  })
  const [focusInput, setFocusInput] = useState<IRegisterParticipantFocusInput>({
    name: initialFocusInputState,
    surname: initialFocusInputState,
    emailAddress: initialFocusInputState,
    password: initialFocusInputState,
    confirmPassword: initialFocusInputState,
    country: initialFocusInputState,
    company: initialFocusInputState,
    plan: initialFocusInputState,
  })
  const [isCompanyFieldRequired, setIsCompanyFieldRequired] = useState<boolean>(false)
  const [isUserExists, setIsUserExists] = useState<boolean>(false)
  const [isUserRegistered, setIsUserRegistered] = useState<boolean>(false)
  const [isUserSocialRegistered, setIsUserSocialRegistered] = useState<boolean>(false)

  const typeOfUsers = useMemo(() => getTypeOfUsers(languageText), [languageText])

  const getCountries = async (): Promise<void> => {
    await getAllCountries(dispatch).then((response: ICountry[]) => {
      if (response?.length > 0) {
        const countries: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.name,
          value: String(item.id),
        }))
        setCountries(countries)
      }
    })
  }

  const getCultures = async () => {
    await getFacilitatorCultures(dispatch).then((response: ICulture[]) => {
      if (response?.length > 0) {
        const cultures: IDropdownList[] = response.map((item) => ({
          id: item.id,
          displayName: item.displayName,
          value: item.id.toString(),
        }))
        setCultures(cultures)
      }
    })
  }

  useEffect(() => {
    getCountries()
    getCultures()

    if (window.location.pathname.endsWith('/facilitator')) {
      setAccountInfo((prevAccountInfo) => ({
        ...prevAccountInfo,
        plan: RegisterParticipantTypeOfUser.Facilitator.toString(),
      }))
      setIsCompanyFieldRequired(true)
    } else if (window.location.pathname.endsWith('/organization')) {
      setAccountInfo((prevAccountInfo) => ({
        ...prevAccountInfo,
        plan: RegisterParticipantTypeOfUser.Organization.toString(),
      }))
      setIsCompanyFieldRequired(true)
    } else if (window.location.pathname.endsWith('/participant')) {
      setAccountInfo((prevAccountInfo) => ({
        ...prevAccountInfo,
        plan: RegisterParticipantTypeOfUser.Individual.toString(),
      }))
      setIsCompanyFieldRequired(false)
    }

    getCountryCode(dispatch).then((response: ICountryLocateResponse) => {
      if (response && response.countryId) {
        setAccountInfo((prevAccountInfo) => ({
          ...prevAccountInfo,
          country: response.countryId === undefined ? '' : response.countryId.toString(),
        }))
      }
    })
  }, [])

  useEffect(() => {
    if (profile && provider) {
      setAccountInfo((prevAccountInfo) => {
        if (provider === SocialProviderTypes.MICROSOFT) {
          if (!profile.given_name && profile.displayName) {
            const parts = profile.displayName.split(' ')
            return {
              ...prevAccountInfo,
              name: parts[0] || '',
              surname: parts.slice(1).join(' ') || '',
              emailAddress: profile.mail || '',
            }
          } else {
            return {
              ...prevAccountInfo,
              name: profile.givenName || '',
              surname: profile.surname || '',
              emailAddress: profile.mail || '',
            }
          }
        }

        // Fallback for other providers or if conditions are not met
        return {
          ...prevAccountInfo,
          name: profile.given_name || prevAccountInfo.name,
          surname: profile.family_name || prevAccountInfo.surname,
          emailAddress: profile.email || prevAccountInfo.emailAddress,
        }
      })
    }
  }, [profile, provider])

  useEffect(() => {
    if (accountInfo.password && focusInput.password.touched && !focusInput.password.errorMessage) {
      setDisableConfirmPassword(false)
    }
  }, [accountInfo.password, focusInput.password.touched])

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    setAccountInfo({
      ...accountInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleDropdownSelect = (selectedItem: IDropdownSelectedItem): void => {
    const { name, value } = selectedItem

    // If user choose “organization“ then we should show company field.
    if (name === RegisterParticipantInputs.plan) {
      if (value !== RegisterParticipantTypeOfUser.Individual.toString()) {
        setAccountInfo({
          ...accountInfo,
          [name]: value,
        })
        setIsCompanyFieldRequired(true)
      } else {
        setAccountInfo({
          ...accountInfo,
          [name]: value,
          company: '', // Resetting company field when user chooses individual
        })
        setIsCompanyFieldRequired(false)
      }
    } else {
      setAccountInfo({
        ...accountInfo,
        [name]: value,
      })
    }

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''

    switch (name) {
      case RegisterParticipantInputs.name:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Name is required')
        }
        break
      case RegisterParticipantInputs.surname:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Surname is required')
        }
        break
      case RegisterParticipantInputs.country:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Country is required')
        }
        break
      case RegisterParticipantInputs.company:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Company is required')
        }
        break
      case RegisterParticipantInputs.plan:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Plan is required')
        }
        break
      case RegisterParticipantInputs.emailAddress:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Email is required')
        } else {
          const isValid = ValidationHelper.isEmailValid(value)
          if (!isValid) errorMessage = getLanguageValue(languageText, 'Invalid email')
        }
        break
      case RegisterParticipantInputs.password:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Password is required')
        } else if (value) {
          const isValid = ValidationHelper.isPasswordValid(value)
          if (!isValid) errorMessage = getLanguageValue(languageText, 'Invalid password')
        }
        break
      case RegisterParticipantInputs.confirmPassword:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Confirm password is required')
        } else if (value !== accountInfo.password) {
          errorMessage = getLanguageValue(languageText, 'Confirm Password does not match')
        }
        break
      default:
        break
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value)
  }

  const isSocialLoginUsed = (): boolean => {
    return provider !== '' && profile !== ''
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !accountInfo.name ||
      focusInput.name.errorMessage ||
      !accountInfo.surname ||
      focusInput.surname.errorMessage ||
      !accountInfo.emailAddress ||
      focusInput.emailAddress.errorMessage ||
      !accountInfo.country ||
      focusInput.country.errorMessage ||
      (isCompanyFieldRequired && (!accountInfo.company || focusInput.company.errorMessage)) ||
      (!isSocialLoginUsed() &&
        (!accountInfo.password ||
          focusInput.password.errorMessage ||
          !accountInfo.confirmPassword ||
          focusInput.confirmPassword.errorMessage))
    ) {
      for (const item in RegisterParticipantInputs) {
        handleFormErrors(item, accountInfo[item])
      }

      return false
    }

    return true
  }

  const handleSubmit = (e: { preventDefault: () => void }): void => {
    e.preventDefault()
    if (!handleValidationOnSubmit()) return
    dispatch(setSpinner(true))

    let providerKey = ''
    if (isSocialLoginUsed()) {
      if (provider === SocialProviderTypes.GOOGLE) {
        providerKey = profile.sub
      } else if (provider === SocialProviderTypes.MICROSOFT) {
        providerKey = profile.id_token ? JSON.parse(atob(profile.id_token.split('.')[1])).oid : ''
      }
    }

    const body: IRegisterParticipantPayload = {
      name: accountInfo.name,
      surname: accountInfo.surname,
      emailAddress: accountInfo.emailAddress,
      password: !provider ? accountInfo.password : '',
      countryId: Number(accountInfo.country),
      company: accountInfo.company,
      typeOfUser: Number(accountInfo.plan),
      isSocialLogin: isSocialLoginUsed(),
      authProvider: provider ? provider.charAt(0).toUpperCase() + provider.slice(1) : '',
      providerAccessToken: profile ? profile.access_token : '',
      providerKey: providerKey,
    }
    registerNewUser(body, dispatch)
      .then((response) => {
        if (response) {
          if (response.isUserExists) {
            setIsUserExists(true)
          }
          if (response.isRegistered) {
            dispatch(addToast('New account registered') as AnyAction)
          }
          if (response.verificationEmailSent) {
            setIsUserRegistered(true)
          }
          if (response.isSocialLogin) {
            setIsUserSocialRegistered(true)
          }

          pushToDataLayer({
            event: 'register_user',
            fields: {
              name: accountInfo.name,
              surname: accountInfo.surname,
              emailAddress: accountInfo.emailAddress,
              countries: accountInfo.country,
              typeOfUsers: accountInfo.plan,
            },
          })
          // If registration is successful, trigger Matomo event
          pushToMatomo({
            event: 'register_user',
            fields: {
              name: accountInfo.name,
              surname: accountInfo.surname,
              emailAddress: accountInfo.emailAddress,
              countries: accountInfo.country,
              typeOfUsers: accountInfo.plan,
            },
          })
        }
      })
      .finally(() => {
        dispatch(setSpinner(false))
      })
  }

  const handleCloseUserExistsPopup = (): void => setIsUserExists(false)

  return {
    typeOfUsers,
    accountInfo,
    focusInput,
    disableConfirmPassword,
    countries,
    cultures,
    isCompanyFieldRequired,
    profile,
    provider,
    isUserExists,
    isUserRegistered,
    isUserSocialRegistered,
    setProvider,
    setProfile,
    handleInputChange,
    handleDropdownSelect,
    handleBlurEvent,
    handleSubmit,
    handleCloseUserExistsPopup,
  }
}
