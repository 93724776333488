import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { IFocusError } from '../../components/formComponents/input'
import { RootState } from '@app/store/configureStore'
import { ValidationHelper } from '../validationHelper'
import { resetPassword } from './actions'
import { getLanguageValue } from '../../commonUtils/languageFunctionsHelper'
import { routePath } from '../routePaths'
import { setPasswordResetCode } from '../auth/signUp/actions'

enum UserInputs {
  newPassword = 'newPassword',
  confirmNewPassword = 'confirmNewPassword',
}

interface IUserInfo {
  newPassword: string
  confirmNewPassword: string
}

interface IFocusInput {
  newPassword: IFocusError
  confirmNewPassword: IFocusError
}

export const useResetPassword = () => {
  const languageText = useSelector((state: RootState) => state.mainReducer.languageText)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const urlParams = searchParams.get('c')

  const passwordResetCode = useSelector((state: RootState) => state.loginReducer.passwordResetCode)
  const userId = useSelector((state: RootState) => state.loginReducer.userId)

  const [userInfo, setUserInfo] = useState<IUserInfo>({
    newPassword: '',
    confirmNewPassword: '',
  })
  const [focusInput, setFocusInput] = useState<IFocusInput>({
    newPassword: {
      touched: false,
      errorMessage: '',
    },
    confirmNewPassword: {
      touched: false,
      errorMessage: '',
    },
  })
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false)

  useEffect(() => {
    // If page is refreshed, we won't be having userId & passwordResetCode in redux, so redirect to login page
    if (!urlParams && (!passwordResetCode || !userId)) {
      navigate(routePath.signIn)
    }
  }, [])

  const handleFormErrors = (name: string, value: string): void => {
    let errorMessage: string = ''

    switch (name) {
      case UserInputs.newPassword:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'New Password is required')
        } else if (value) {
          const isValid = ValidationHelper.isPasswordValid(value)
          if (!isValid) errorMessage = getLanguageValue(languageText, 'Provide valid password')
        }
        break
      case UserInputs.confirmNewPassword:
        if (!value) {
          errorMessage = getLanguageValue(languageText, 'Confirm Password is required')
        } else if (value !== userInfo.newPassword) {
          errorMessage = getLanguageValue(languageText, 'Confirm Password does not match')
        }
        break
      default:
        break
    }

    setFocusInput((prev) => ({
      ...prev,
      [name]: {
        touched: true,
        errorMessage: errorMessage,
      },
    }))
  }

  const handleBlurEvent = (e: React.FocusEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value
    handleFormErrors(name, value)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name
    const value = e.target.value

    setUserInfo({
      ...userInfo,
      [name]: value,
    })

    if (focusInput[name]?.touched) {
      handleFormErrors(name, value)
    }
  }

  const handleValidationOnSubmit = (): boolean => {
    if (
      !userInfo.newPassword ||
      focusInput.newPassword.errorMessage ||
      !userInfo.confirmNewPassword ||
      focusInput.confirmNewPassword.errorMessage
    ) {
      for (const item in UserInputs) {
        handleFormErrors(item, userInfo[item])
      }
      return false
    }
    return true
  }

  const handleSubmit = (event: React.FormEvent): void => {
    event.preventDefault()

    if (!handleValidationOnSubmit()) return
    setIsSubmitting(true)
    //We have to pass 0, otherwise in API, the request won't be processed if passed null. When reset password link is opened, there is no userId and passed null, so passing 0 now.
    const body = {
      userId: urlParams ? 0 : userId,
      password: userInfo.newPassword,
      c: urlParams,
      resetCode: passwordResetCode,
    }

    resetPassword(body, dispatch)
      .then((response) => {
        if (response?.status === 200) {
          dispatch(setPasswordResetCode(''))
          setShowSuccessModal(true)
        }
      })
      .finally(() => setIsSubmitting(false))
  }

  const handleRedirection = (): void => {
    navigate(routePath.signIn)
  }

  return {
    UserInputs,
    userInfo,
    focusInput,
    isSubmitting,
    handleBlurEvent,
    handleInputChange,
    handleSubmit,
    languageText,
    showSuccessModal,
    handleRedirection,
  }
}
